import { useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { closeModal } from '../../actions/common.actions';
import FilterForm from './FilterForm';

import icon_filter from '../../assets/icons/icon_filter.svg';
import icon_close from '../../assets/icons/icon_cross.svg';
import { useMembers } from '../../views/Contacts/useMembers';
import { CONTACTS_PAGE_SIZE } from '../../views/Contacts/Contacts.const';
import { useAppDispatch, useAppSelector } from '../../helpers/store';

const FilterPanel = ({ onClose }: { onClose: () => void }) => {
  const { filterForm } = useAppSelector((state) => state.form);
  const filterValues = useRef<string>(filterForm?.values ? JSON.stringify(filterForm?.values) : '');

  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ closeModal }, dispatch);

  const { getMembers } = useMembers();

  // const updateContactsData = useCallback(() => {
  //   getMembers({
  //     pageNumber: 1,
  //     pageSize: CONTACTS_PAGE_SIZE,
  //     search: searchValue
  //   });
  // }, [filterForm?.values]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (filterForm?.values || filterForm?.values === undefined) {
        const newFilterValues = filterForm?.values ? JSON.stringify(filterForm?.values) : '';
        if (filterValues.current !== newFilterValues) {
          filterValues.current = newFilterValues;
          getMembers({
            pageNumber: 1,
            pageSize: CONTACTS_PAGE_SIZE
          });
        }
      }
    }, 0);
    return () => clearTimeout(id);
  }, [filterForm?.values]);

  return (
    <div className="filter-panel">
      <div className="padding-left padding-right filter-panel-header">
        <div className="filter-panel-top-row">
          <img src={icon_filter} />
          <span className="title-text padding-xsm-right">All filters</span>
          {/*TODO change this to button image */}
          <img
            className="filter-form-dismiss"
            onClick={() => {
              onClose();
              actions.closeModal();
            }}
            src={icon_close}
          />
        </div>
      </div>
      <FilterForm />
    </div>
  );
};

export default FilterPanel;
