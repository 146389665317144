import { FC, useMemo, useCallback } from 'react';
import { Chip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { FILTER_FORM } from '../../../constants/forms';
import { RootState } from '../../../reducers/index.types';

import icon_close_mini from '../../../assets/icons/icon_close_mini.svg';
import { useMembers } from '../useMembers';

type OptionType = {
  value: string;
  name: string;
};

type MaritalStatusType = {
  Married: boolean;
  Single: boolean;
};
type GenderType = 'Male' | 'Female';
type FollowType = {
  Unassigned: boolean;
};

export type FilterFormValuesType = {
  adminAttributes: OptionType[];
  attributes: OptionType[];
  emailType: string;
  followUp: FollowType;
  audiences: OptionType[];
  gender: GenderType;
  maritalStatus: MaritalStatusType;
  muktType: OptionType[];
};

export const FilterChips: FC = () => {
  const form = useSelector((state: RootState) => state.form);
  const filterFormValues: Record<string, MaritalStatusType | FollowType | OptionType[] | string> =
    form?.[FILTER_FORM]?.values ?? {};

  const { searchValue } = useSelector((state: RootState) => state.contactsData);
  const { getMembers } = useMembers();

  const dispatch = useDispatch();

  const handleFilterOptionClick = useCallback(
    (event: any, filterFormValues: FilterFormValuesType, arrayOption: OptionType | null) => {
      const filterOption = event.currentTarget.getAttribute('data-parent')
        ? event.currentTarget.getAttribute('data-parent')
        : event.target.parentElement.getAttribute('data-parent');

      if (filterFormValues && typeof filterFormValues === 'object') {
        const filterFormKeys = Object.keys(filterFormValues);
        if (filterFormKeys && filterFormKeys.length) {
          const foundKey = filterFormKeys.find((key) => filterOption.includes(key));
          if (foundKey && arrayOption) {
            const newFilterValues = getFilterChipFormValues(
              filterFormValues,
              foundKey,
              arrayOption
            );
            dispatch(change(FILTER_FORM, foundKey, newFilterValues));
            getMembers({
              search: searchValue,
              filter: { [foundKey]: newFilterValues.map((f) => f.value) }
            });
          } else if (foundKey) {
            dispatch(change(FILTER_FORM, foundKey, ''));
            getMembers({ search: searchValue, filter: {} });
          }
        }
      }
    },
    [dispatch, getMembers, searchValue]
  );

  const filterChipValues = useMemo(() => {
    const chips: any[] = [];
    if (filterFormValues && typeof filterFormValues === 'object') {
      Object.entries(filterFormValues).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          Object.entries(value).forEach(([subKey, subValue]) => {
            if (subValue === true) {
              chips.push({
                parent: `${key}.${subKey}`,
                onClick: (e: any) =>
                  handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null),
                label: subKey.split('+').join(' ')
              });
            } else if (typeof subValue === 'object') {
              chips.push({
                parent: `${key}.${subValue?.value}`,
                onClick: (e: any) =>
                  handleFilterOptionClick(
                    e,
                    filterFormValues as FilterFormValuesType,
                    subValue as OptionType
                  ),
                label: subValue?.name
              });
            }
          });
        } else if (typeof value === 'string') {
          chips.push({
            parent: `${key}.${value}`,
            onClick: (e: any) =>
              handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null),
            label: value
          });
        }
      });
    }
    return chips;
  }, [filterFormValues, handleFilterOptionClick]);

  return (
    <div className="contacts-filters-container">
      {filterChipValues.map((chip, index) => (
        <Chip
          data-parent={chip.parent}
          onDelete={chip.onClick} // Ensure this is consistent
          key={`${index}-${chip.label}`}
          onClick={chip.onClick}
          deleteIcon={<img style={{ height: 8, width: 8 }} src={icon_close_mini} alt="delete" />}
          className="chip"
          label={<span className="chip-label">{chip.label}</span>}
        />
      ))}
    </div>
  );
};

function getFilterChipFormValues(
  filterFormValues: FilterFormValuesType,
  foundKey: string,
  option: OptionType
): OptionType[] {
  let optionArray: OptionType[] = [];
  switch (foundKey) {
    case 'muktType':
      optionArray = filterFormValues.muktType;
      break;
    case 'adminAttributes':
      optionArray = filterFormValues.adminAttributes;
      break;
    case 'attributes':
      optionArray = filterFormValues.attributes;
      break;
    case 'audiences':
      optionArray = filterFormValues.audiences;
      break;
    default:
      console.error('Unknown Filter Form Value');
  }
  return optionArray.filter((optionArrayValue) => optionArrayValue.value !== option.value);
}
