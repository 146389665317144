import React, { useMemo } from 'react';
import { change, reduxForm, Field } from 'redux-form';
import uniq from 'lodash/uniq';
import { FormControlLabel, Radio } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  buildMultiCheckboxOptions,
  buildNameValueArray,
  mapObjectToOptionValueArray
} from '../../util/contactUtils';
import * as FORMS from '../../constants/forms';
import {
  checkUserPermission,
  getAccessObj,
  GENDER,
  ISSUPERADMIN,
  HASFOLLOWUP,
  CANUPDATECONTACTS,
  CANEDITADMINATTRIBUTES
} from '../../util/userPermission';
import * as PERMISSIONS from '../../constants/permissions';
import {
  CheckboxSelectField,
  renderCheckboxField,
  renderRadioGroup,
  renderSelectField
} from '../form-fields';

import icon_radio_selected_small from '../../assets/icons/icon_radio_selected_small.svg';
import icon_radio_unselected_small from '../../assets/icons/icon_radio_unselected_small.svg';
import { RootState } from '../../reducers/index.types';
import { useFeature } from '@growthbook/growthbook-react';

const reference = ['No Reference'];
const followUpOptions = [
  {
    label: 'Unassigned',
    value: 'unassigned'
  }
];
const genderOptions = ['Male', 'Female'];
const emailTypeOptions = [
  {
    label: 'Gmail',
    value: 'gmail'
  },
  {
    label: 'Non-Gmail',
    value: 'non-gmail'
  },
  {
    label: 'No-Email',
    value: 'no-email'
  }
];

const FilterForm = () => {
  const { contactOptions } = useSelector((state: RootState) => state.contactsData);

  const { userAccess } = useSelector((state: RootState) => state);
  const showUnverifiedFilter = useFeature('sampark.show-unverified-filter').on;

  const isSuperAdmin = checkUserPermission(userAccess, ISSUPERADMIN);
  const userAccessGender = getAccessObj(userAccess, GENDER, PERMISSIONS.CONTACTS);
  const hasFollowUpAccess = checkUserPermission(userAccess, HASFOLLOWUP);
  const hasContactUpdateAccesss = checkUserPermission(userAccess, CANUPDATECONTACTS);
  const canEditAdminAttributes = checkUserPermission(userAccess, CANEDITADMINATTRIBUTES);

  const dispatch = useDispatch();

  const { muktTypes, maritalStatus, mandals, attributes, adminAttributes, audiencesList } =
    contactOptions;
  const audiencesOptions = buildMultiCheckboxOptions('audiences', audiencesList?.contacts);

  const attributeOptions = buildMultiCheckboxOptions('Attributes', attributes);
  const adminAttributeOptions = buildMultiCheckboxOptions('Admin Attributes', adminAttributes);
  let muktTypeCategory;

  if (isSuperAdmin) {
    //TODO fix this
    // @ts-ignore
    muktTypeCategory = buildNameValueArray(uniq([...muktTypes.male, ...muktTypes.female]));
  } else {
    // TODO: userPermissions Fix
    muktTypeCategory = userAccessGender
      ? buildNameValueArray(muktTypes[userAccessGender.toLowerCase()])
      : [];
  }

  const muktTypeCategoryOptions = buildMultiCheckboxOptions('Mukt Type', muktTypeCategory);

  const mandalOptions = useMemo(() => {
    if (isSuperAdmin) {
      const allMandalsObject = genderOptions.reduce((acc, curr) => {
        return {
          ...acc,
          ...mandals[curr.toLowerCase()]
        };
      }, {});
      return mapObjectToOptionValueArray(allMandalsObject);
    } else if (genderOptions.includes(userAccessGender)) {
      return mapObjectToOptionValueArray(mandals[userAccessGender.toLowerCase()]);
    }

    return [];
  }, [isSuperAdmin, userAccessGender, mandals]);

  return (
    <div className="filter-form-container">
      <div className="filter-section">
        <div className="section-header">Reference</div>
        {reference.map((option, index) => {
          return (
            <Field
              label={option}
              key={`${option}-${index}`}
              name={`noReference`}
              inline
              component={renderCheckboxField}
            />
          );
        })}
      </div>
      {hasFollowUpAccess ? (
        <div className="filter-section">
          <div className="section-header">Follow Up</div>
          {followUpOptions.map((option, index) => {
            return (
              <Field
                label={option.label}
                key={`${option.value}-${index}`}
                name={`followUp.${option.value}`}
                inline
                component={renderCheckboxField}
              />
            );
          })}
        </div>
      ) : null}
      {showUnverifiedFilter && hasContactUpdateAccesss ? (
        <div className="filter-section">
          <div className="section-header">Unverified</div>
          <Field
            label={'View unverified Contacts'}
            name={`unverified`}
            inline
            component={renderCheckboxField}
          />
        </div>
      ) : null}
      <div className="filter-section">
        <div className="section-header">Marital Status</div>
        <div className="filter-container inline">
          <Field
            className={'column-radio-group'}
            name="maritalStatus"
            component={renderRadioGroup}
            props={{
              className: 'inline'
            }}
          >
            {maritalStatus.map((item: string, i: number) => {
              return (
                <FormControlLabel
                  name={`maritalStatus.${item}`} // `
                  key={i}
                  value={item}
                  control={
                    <Radio
                      disableRipple
                      checkedIcon={<img src={icon_radio_selected_small} />}
                      icon={<img src={icon_radio_unselected_small} />}
                    />
                  }
                  label={item}
                />
              );
            })}
          </Field>
        </div>
      </div>
      {isSuperAdmin ? (
        <div className="filter-section" style={{ position: 'relative' }}>
          <div className="section-header">Gender</div>
          <a
            className={['link', 'gender-clear-link'].join(' ')}
            onClick={() => {
              //TODO fix this
              // @ts-ignore
              dispatch(change(FORMS.FILTER_FORM, 'gender', ''));
            }}
          >
            Clear
          </a>
          <Field
            className={'column-radio-group'}
            name="gender"
            component={renderRadioGroup}
            props={{
              className: 'inline'
            }}
          >
            {genderOptions.map((option, index) => {
              return (
                <FormControlLabel
                  name={`gender.'${option}`}
                  key={index}
                  value={option}
                  control={
                    <Radio
                      disableRipple
                      checkedIcon={<img src={icon_radio_selected_small} />}
                      icon={<img src={icon_radio_unselected_small} />}
                    />
                  }
                  label={option}
                />
              );
            })}
          </Field>
        </div>
      ) : null}
      {audiencesOptions?.value?.length ? (
        <div className="filter-section" style={{ position: 'relative' }}>
          <div className="section-header">Audiences</div>
          <a
            className={['link', 'audiences-clear-link'].join(' ')}
            onClick={() => {
              //TODO fix this
              // @ts-ignore
              dispatch(change(FORMS.FILTER_FORM, 'audiences', []));
            }}
          >
            Clear
          </a>
          <div className="select-field">
            <Field // checkbox
              label=""
              options={[audiencesOptions]}
              name="audiences"
              placeholder="Filter by tags"
              displaySelectedCount
              buttonId="field-audiences-selector"
              dropdownMaxHeight={465}
              buttonLabel={`audience(s) selected`}
              component={CheckboxSelectField}
              renderSelectedChipsBelow
            />
          </div>
        </div>
      ) : null}
      {mandalOptions?.length ? (
        <div className="filter-section" style={{ position: 'relative' }}>
          <div className="section-header">Mandal</div>
          <a
            className={['link', 'mandal-clear-link'].join(' ')}
            onClick={() => {
              //TODO fix this
              // @ts-ignore
              dispatch(change(FORMS.FILTER_FORM, 'mandal', ''));
            }}
          >
            Clear
          </a>
          <div className="select-field">
            <Field
              name="mandal"
              component={renderSelectField}
              placeholder="Filter by mandals"
              defaultValue={mandalOptions[0].value}
              options={mandalOptions}
              // TODO fix type
              format={(value: any) => value || ''}
            />
          </div>
        </div>
      ) : null}
      <div className="filter-section" style={{ position: 'relative' }}>
        <div className="section-header">Attributes</div>
        <a
          className={['link', 'tag-clear-link'].join(' ')}
          onClick={() => {
            //TODO fix this
            // @ts-ignore
            dispatch(change(FORMS.FILTER_FORM, 'attributes', []));
          }}
        >
          Clear
        </a>
        <div className="select-field">
          <Field // checkbox
            label=""
            options={[attributeOptions]}
            name="attributes"
            placeholder="Filter by tags"
            displaySelectedCount
            buttonId="field-attribute-selector"
            dropdownMaxHeight={465}
            buttonLabel={`attribute(s) selected`}
            component={CheckboxSelectField}
            renderSelectedChipsBelow
          />
        </div>
      </div>
      <div className="filter-section" style={{ position: 'relative' }}>
        <div className="section-header">Mukt Type</div>
        <a
          className={['link', 'tag-clear-link'].join(' ')}
          onClick={() => {
            //TODO fix this
            // @ts-ignore
            dispatch(change(FORMS.FILTER_FORM, 'muktType', []));
          }}
        >
          Clear
        </a>
        <div className="select-field">
          <Field // checkbox
            label=""
            options={[muktTypeCategoryOptions]}
            name="muktType"
            placeholder="Filter by MuktType"
            displaySelectedCount
            buttonId="field-muktType-selector"
            dropdownMaxHeight={465}
            buttonLabel={`Mukt Type(s) selected`}
            component={CheckboxSelectField}
            renderSelectedChipsBelow
          />
        </div>
      </div>
      {canEditAdminAttributes ? (
        <div className="filter-section" style={{ position: 'relative' }}>
          <div className="section-header">Admin Attributes</div>
          <a
            className={['link', 'tag-clear-link'].join(' ')}
            onClick={() => {
              //TODO fix this
              // @ts-ignore
              dispatch(change(FORMS.FILTER_FORM, 'adminAttributes', []));
            }}
          >
            Clear
          </a>
          <div className="select-field">
            <Field // checkbox
              label=""
              options={[adminAttributeOptions]}
              name="adminAttributes"
              placeholder="Filter by Admin Attributes"
              displaySelectedCount
              buttonId="field-admin-attributes-selector"
              dropdownMaxHeight={465}
              buttonLabel={`Admin Attribute(s) selected`}
              component={CheckboxSelectField}
              renderSelectedChipsBelow
            />
          </div>
        </div>
      ) : null}
      <div className="filter-section" style={{ position: 'relative' }}>
        <div className="section-header">Email Users</div>
        <a
          className={['link', 'emailType-clear-link'].join(' ')}
          onClick={() => {
            //TODO fix this
            // @ts-ignore
            dispatch(change(FORMS.FILTER_FORM, 'emailType', ''));
          }}
        >
          Clear
        </a>
        <Field className={'column-radio-group'} name="emailType" component={renderRadioGroup}>
          {emailTypeOptions.map((option, index) => {
            return (
              <FormControlLabel
                name={`emailType.'${option.value}`} // `
                key={index}
                value={option.value}
                control={
                  <Radio
                    disableRipple
                    checkedIcon={<img src={icon_radio_selected_small} />}
                    icon={<img src={icon_radio_unselected_small} />}
                  />
                }
                label={option.label}
              />
            );
          })}
        </Field>
      </div>
    </div>
  );
};
/* eslint-disable */
const FilterFormWithRedux = reduxForm({
  form: FORMS.FILTER_FORM,
  destroyOnUnmount: false
})(FilterForm);

export default FilterFormWithRedux;
