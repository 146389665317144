import Dialog from '@material-ui/core/Dialog';
// Project Files
import * as MODAL_COMPONENTS from '../../constants/modals';
import {
  ErrorModal,
  DeleteContactsModal,
  ContactUsModal,
  InviteMemberModal,
  RejectBroadcastMessageModal,
  CancelBroadcastModal,
  RejectSambandhModal,
  EventGalleryModal,
  RejectReadingModal
} from './index';
import ContactPanel from '../contact-panel/ContactPanel';
import { StyledContactInfo } from '../../views/Contacts/components/ContactInfo';
import ReadingPanel from '../reading-panel/ReadingPanel';
import { closeModal } from '../../actions/common.actions';
import { useAppDispatch, useAppSelector } from '../../helpers/store';

const ModalController = () => {
  const dispatch = useAppDispatch();

  const modalComponent = useAppSelector((state) => state.commonData.modalComponent);
  const modalProps = useAppSelector((state) => state.commonData.modalProps);
  const showModal = useAppSelector((state) => state.commonData.showModal);

  const renderModalContent = (): JSX.Element => {
    switch (modalComponent) {
      case MODAL_COMPONENTS.CONTACT_INFO_READ_ONLY:
        return <StyledContactInfo contact={modalProps.contact} />;
      case MODAL_COMPONENTS.CONTACT_FORM:
        return <ContactPanel />;
      case MODAL_COMPONENTS.USER_ACCOUNT_PANEL:
        return <ContactPanel myUserAccount />;
      case MODAL_COMPONENTS.VIEW_READING_MODAL:
        return <ReadingPanel {...modalProps} />; //TODO remove this if not used anymore
      case MODAL_COMPONENTS.ERROR_MODAL:
        return <ErrorModal errorMessage={modalProps.errorMessage} />;
      case MODAL_COMPONENTS.DELETE_CONTACTS_MODAL:
        return <DeleteContactsModal contactsToDelete={modalProps.selectedContacts} />;
      case MODAL_COMPONENTS.CONTACT_US_MODAL:
        return <ContactUsModal loggedInUser={modalProps.loggedInUser} />;
      case MODAL_COMPONENTS.INVITE_MEMBER_MODAL:
        return <InviteMemberModal contact={modalProps.contact} />;
      case MODAL_COMPONENTS.REJECT_BROADCAST_MODAL:
        return <RejectBroadcastMessageModal />;
      case MODAL_COMPONENTS.CANCEL_BROADCAST_MODAL:
        return <CancelBroadcastModal message={modalProps.message} />;
      case MODAL_COMPONENTS.REJECT_SAMBANDH_MODAL:
        return <RejectSambandhModal />;
      case MODAL_COMPONENTS.EVENT_GALLERY_MODAL:
        return <EventGalleryModal event={modalProps.event} />;
      case MODAL_COMPONENTS.REJECT_READING_MODAL:
        return <RejectReadingModal />;
      default:
        return <div></div>;
    }
  };

  const className = modalProps?.className ?? '';
  const fullScreen = window.innerWidth < 768;
  const maxWidth = modalProps?.maxWidth === 'md' ? 'md' : 'sm';

  return (
    <Dialog
      className={`contacts-selected-dialog ${className}`}
      fullScreen={fullScreen}
      open={showModal}
      onClose={() => dispatch(closeModal())}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth={maxWidth}
      disableEscapeKeyDown
    >
      {renderModalContent()}
    </Dialog>
  );
};

export default ModalController;
